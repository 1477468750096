export const dblue = "#355573";
export const lblue = "#7ea0bf";
export const dbrown = "#735f40";
export const brown = "#bfa67e";
export const lbrown = "#ffe8c2";
export const lgray = "#f8f9fd";

export const colors = {
  dblue: "#355573",
  lblue: "#7ea0bf",
  dbrown: "#735f40",
  brown: "#bfa67e",
  lbrown: "#ffe8c2",
  lgray: "#f8f9fd",
};
